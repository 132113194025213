
import Vue from "vue";
import { Empty } from "ant-design-vue";
import PasswordLogin from "./components/PasswordLogin.vue";
import QRcodeLogin from "./components/QRcodeLogin.vue";
import TextLogin from "./components/TextLogin.vue";

export default Vue.extend({
  name: "Login",
  components: { [Empty.name]: Empty, PasswordLogin, QRcodeLogin, TextLogin },
  props: {},
  data() {
    return {
      action: "password",
      loginType: [
        { title: "扫码登录", value: "QRcode" },
        { title: "账号登录", value: "password" },
        { title: "验证码登录", value: "text" },
      ],
      // 保持登录状态
      keep: true,
    };
  },
  computed: {},
  created() {
    if (this.$route.query.visitor === "1") {
      let params = {
        loginId: "test@csii.com.cn",
        password: "123456",
        ipaddr:
          (window as any).returnCitySN && (window as any).returnCitySN["cip"],
        loginLocation:
          (window as any).returnCitySN && (window as any).returnCitySN["cname"],
      };
      this.$apis.login(params).then((res: any) => {
        if (res.code === "200") {
          // 点击保持登录存储一份token
          sessionStorage.setItem("X-Access-Token", JSON.stringify(res.data));
          localStorage.setItem("userId", res.data.userId);
          // this.$store.commit("user/SET_USER", res.data);
          this.$store.dispatch("user/GET_USERINFO", "userinfo");
          // 去到首页
          console.log(this.$route.query.type);
          // this.$router.push({ path: "/star" });
          let query: any = {};
          switch (this.$route.query.type) {
            case "xlsx":
              query = {
                userFileId: "372681710248325120",
                moduleType: "doc",
                fileExtension: "xlsx",
                fileName: "体验表格",
                ot: "edit",
              };
              break;
            case "pptx":
              query = {
                userFileId: "372681739319042048",
                moduleType: "doc",
                fileExtension: "pptx",
                fileName: "体验幻灯片",
                ot: "edit",
              };
              break;
            default:
              query = {
                userFileId: "372681680426819584",
                moduleType: "doc",
                fileExtension: "docx",
                fileName: "体验文档",
                ot: "edit",
              };
              break;
          }
          this.$router.replace({
            name: "VIEW_OFFICE_ONLINE",
            query,
          });
        } else {
          this.$message.warning(res.msg);
        }
      });
    }
  },
  mounted() {},
  methods: {
    handleChecked(value: string): void {
      this.action = value;
    },
    handleChange(e: any): void {
      this.keep = e.target.checked;
    },
    // 去到找回密码页面
    gotoRetrievePassword(): void {
      this.$router.push({ path: "/user/retrievePassword" });
    },
    // 去到个人注册页面
    gotoPrivateRegister(): void {
      this.$router.push({ path: "/user/privateRegister" });
    },
    // 去到企业注册页面
    gotoEnterpriseRegistration(): void {
      this.$router.push({ path: "/user/enterpriseRegister" });
    },
    // 单点登录
    ssoLogin() {
      this.$apis.getCode().then((res: any) => {
        if (res.code === "200") {
          window.location.href = res.data;
        }
      });
    },
  },
});
