
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { UserLogin } from "@/interface";
import { namespace } from "vuex-class";
import CsiiCaptcha from "./code/csii.captcha.umd.js";
import axios from "@/common/utils/request";

const UserModule = namespace("user");
const CommonModule = namespace("common");
const shareModule = namespace("share");
const teamModule = namespace("team");
const cooperationModule = namespace("cooperation");

@Component({
  name: "PasswordLogin",
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class PasswordLogin extends Vue {
  // 账号登录表单
  public userFormData: UserLogin = {
    loginId: "",
    password: "",
    verify: false,
    // code: "",
  };
  public csiiCaptcha: any = null;
  public csiiCaptchaData: any = {};
  // 验证规则
  public rules: any = {
    loginId: [
      { required: true, message: "请输入邮箱或手机号", trigger: "blur" },
    ],
    password: [{ required: true, message: "请输入密码", trigger: "blur" }],
    // code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
  };
  // 验证码
  public verificationUrl: string = "";
  // 验证码唯一标识
  public clientId: string = "";
  // 登录loading
  public loading: boolean = false;

  // @UserModule.Mutation("SET_USER") setUser!: ({}) => void;
  @UserModule.Action("GET_USERINFO") getUserinfo!: (type: string) => void;
  @CommonModule.State("navigator") navigator!: string;
  @shareModule.Mutation("SET_LOGINSHOW") setLoginShow!: (
    value: boolean
  ) => void;
  @shareModule.Mutation("SET_OFFICELOGINSHOW") setOfficeLoginShow!: (
    value: boolean
  ) => void;
  @teamModule.Mutation("SET_TEAMINVITEDLOGINSHOW") setTeamInvitedLoginShow!: (
    value: boolean
  ) => void;
  @cooperationModule.Mutation("SET_INVITEDLOGINSHOW")
  setCooperationLoginShow!: (value: boolean) => void;

  creatcsiiCaptcha() {
    const that: any = this;
    CsiiCaptcha.config({
      baseUrl: "http://192.168.100.1:8001/captcha",
      // baseUrl: "https://yzm.csii.com.cn/captcha",
      channel: "star-doc", // 渠道, 默认pweb
      timeout: 30 * 1000, // 超时
      locale: "zh-CN", // 国际化语言
      dataType: "jsonp", // (jsonp/json) 默认 jsonp,使用json需要跨域
    });
    this.csiiCaptcha = CsiiCaptcha.create({
      trialing: "slide",
      mode: "float",
      element: this.csiiCaptchakRef,
      width: "432px",
      scene: "loginPage", // 设置使用场景, 非必要选项
      onInit: function (flag: any) {
        // flag is tell you op is zero, can use remote captcha service
        console.log("on Init falg:", flag);
      },
      onReady: function (instance: any) {
        console.log("onload....", instance);
      },
      onError: function (err: any) {
        // 初始化失败后触发该函数，err对象描述当前错误信息
        console.log("err....", err);
      },
      onRefresh: function (err: any) {
        // 验证码刷新时回调，err对象描述当前错误信息
        console.log("refresh....", err);
      },
      onVerify: function (err: any, twiceVerifyData: any) {
        console.log(err, twiceVerifyData);
        // var sign = `capCode=${twiceVerifyData.capCode}ch=${twiceVerifyData.ch}scene=${twiceVerifyData.scene}token=${twiceVerifyData.token}verifyToken=${twiceVerifyData.verifyToken}`;
        // console.log(that.$md5(sign));
        that.csiiCaptchaData = {
          verifyToken: twiceVerifyData.verifyToken,
          // ch: twiceVerifyData.ch,
          capCode: twiceVerifyData.capCode,
          scene: twiceVerifyData.scene,
          token: twiceVerifyData.token,
          // sign: sign,
        };
        that.$apis.captchaVerify(that.csiiCaptchaData)
          .then((res: any) => {
            console.log(res)
            if (res.data) {
              that.userFormData.verify = true;
            } else {
              that.$message.warning('验证失败,请重试!')
              that.userFormData.verify = false;
              that.csiiCaptcha.refresh();
            }
          })
          .catch((err: any) => {
            that.userFormData.verify = false;
          });
      },
    });
  }

  // 登录
  handleLogin(): void {
    this.ruleForm.validate((valid: boolean) => {
      if (!this.userFormData.verify) {
        this.$message.warning("请进行验证");
        return false;
      }
      let params = {
        loginId: this.userFormData.loginId,
        password: this.userFormData.password,
        // ipaddr:
        //   (window as any).returnCitySN && (window as any).returnCitySN["cip"],
        // loginLocation:
        //   (window as any).returnCitySN && (window as any).returnCitySN["cname"],
        // clientId: this.clientId,
      };
      if (valid) {
        this.loading = true;
        this.$apis
          .login(params)
          .then((res: any) => {
            if (res.code === "200") {
              // 点击保持登录存储一份token
              if (this.keep) {
                localStorage.setItem("loginId", this.userFormData.loginId);
              } else {
                localStorage.removeItem("loginId");
              }
              sessionStorage.setItem(
                "X-Access-Token",
                JSON.stringify(res.data)
              );
              localStorage.setItem("userId", res.data.userId);

              // this.setUser(res.data);
              this.getUserinfo("userinfo");

              // 是否登录超时返回
              if (this.$route.path.startsWith("/fileShare")) {
                // 分享页面登录
                this.setLoginShow(false);
              } else if (this.$route.path.startsWith("/officeOnline")) {
                // 文档查看页面需要重新渲染
                this.setOfficeLoginShow(false);
                window.location.reload();
              } else if (this.$route.path.startsWith("/teamInvited")) {
                // 团队邀请页面登录
                this.setTeamInvitedLoginShow(false);
              } else if (this.$route.path.startsWith("/cooperationInvited")) {
                // 协作邀请页面登录
                this.setCooperationLoginShow(false);
              } else if (this.$route.query.hasOwnProperty("path")) {
                // 重新回到之前浏览页面
                this.$router.replace(this.$route.query.path as string);
              } else {
                // 去到首页
                this.$router.push({ path: "/star" });
              }
            } else {
              this.SliderCheckInit();
            }
          })
          .catch(() => {
            this.SliderCheckInit();
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$message.warning("请检查输入，不能为空！");
      }
    });
  }

  // 重置拖拽验证
  SliderCheckInit(): void {
    if (this.userFormData.verify) {
      this.userFormData.verify = false;
      this.csiiCaptcha.refresh();
    }
  }

  // 获取验证码
  getVerificationUrl(): void {
    this.clientId = this.createClientId();
    this.$apis.kaptcha(this.clientId).then((res: any) => {
      if (res) this.verificationUrl = window.URL.createObjectURL(res);
    });
  }

  // 创建唯一标识
  createClientId(): string {
    let now = Date.now();
    var d = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return now + "-" + uuid;
  }

  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({ required: true })
  readonly keep!: boolean;
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  @Ref("ruleForm")
  ruleForm!: any;
  @Ref("csiiCaptcha")
  csiiCaptchakRef!: any;
  //创建前钩子函数
  created(): void {
    if (localStorage.getItem("loginId")) {
      this.userFormData.loginId = localStorage.getItem("loginId") as string;
    }
  }
  mounted() {
    this.creatcsiiCaptcha();
  }
}
