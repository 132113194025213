
import { clearInterval } from "timers";
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const UserModule = namespace("user");
const CommonModule = namespace("common");
const shareModule = namespace("share");
const teamModule = namespace("team");
const cooperationModule = namespace("cooperation");

function createId() {
  let now = Date.now();
  var d = new Date().getTime();
  var uuid = "xyyx".replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 10) % 10 | 0;
    d = Math.floor(d / 10);
    return r.toString();
  });
  console.log(uuid);
  return now + uuid;
}

@Component({
  name: "QRcodeLogin",
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class QRcodeLogin extends Vue {

  // @UserModule.Mutation("SET_USER") setUser!: ({}) => void;
  @UserModule.Action("GET_USERINFO") getUserinfo!: (type: string) => void;
  @CommonModule.State("navigator") navigator!: string;
  @shareModule.Mutation("SET_LOGINSHOW") setLoginShow!: (
    value: boolean
  ) => void;
  @shareModule.Mutation("SET_OFFICELOGINSHOW") setOfficeLoginShow!: (
    value: boolean
  ) => void;
  @teamModule.Mutation("SET_TEAMINVITEDLOGINSHOW") setTeamInvitedLoginShow!: (
    value: boolean
  ) => void;
  @cooperationModule.Mutation("SET_INVITEDLOGINSHOW") setCooperationLoginShow!: (
    value: boolean
  ) => void;

  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()

  public codeImg: string = "";
  public spinning: boolean = false;
  public timer: any = null;
  public count: number = 0;
  public socketId: string = "";

  getAppCode(): void {
    if (this.timer) window.clearInterval(this.timer);
    this.spinning = true;
    this.socketId = createId();
    this.$apis
      .wechatGetAppCode({
        checkPath: true,
        page: `subpkg/login/login/login`,
        scene: this.socketId,
        width: 210,
        envVersion: process.env.NODE_ENV === 'development' ? "develop" : "release",
      })
      .then((res: any) => {
        if (res.code === "200") {
          this.codeImg = "data:image/png;base64," + res.data.buffer;
          this.socketLogin();
        }
      })
      .finally((e: any) => {
        this.spinning = false;
      });
  }

  socketLogin(): void {
    this.count = 0;
    this.timer = setInterval(() => {
      this.count++;
      console.log(this.count);
      this.$apis.qrcodeLogin({ socketId: this.socketId }).then((res: any) => {
        if (res.code === "200") {
          window.clearInterval(this.timer)
          sessionStorage.setItem("X-Access-Token", JSON.stringify(res.data));
          localStorage.setItem("userId", res.data.userId);

          // this.setUser(res.data);
          this.getUserinfo("userinfo");

          // 是否登录超时返回
          if (this.$route.path.startsWith("/fileShare")) {
            // 分享页面登录
            this.setLoginShow(false);
          } else if (this.$route.path.startsWith("/officeOnline")) {
            // 文档查看页面需要重新渲染
            this.setOfficeLoginShow(false);
            window.location.reload();
          } else if (this.$route.path.startsWith("/teamInvited")) {
            // 团队邀请页面登录
            this.setTeamInvitedLoginShow(false);
          } else if (this.$route.path.startsWith("/cooperationInvited")) {
            // 协作邀请页面登录
            this.setCooperationLoginShow(false);
          } else if (this.$route.query.hasOwnProperty("path")) {
            // 重新回到之前浏览页面
            this.$router.replace(this.$route.query.path as string);
          } else {
            // 去到首页
            this.$router.push({ path: "/star" });
          }
        }
      });
      if (this.count >= 60 && this.timer) window.clearInterval(this.timer);
    }, 5000);
  }

  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')

  //创建前钩子函数
  created(): void {
    this.getAppCode();
  }

  beforeDestroy(): void {
    if (this.timer) window.clearInterval(this.timer);
  }
}
