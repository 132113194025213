
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
// 引入倒计时混入
import { mixins } from "vue-class-component";
import CountDown from "./mixin/countDown";
import { namespace } from "vuex-class";

const UserModule = namespace("user");
const shareModule = namespace("share");
const teamModule = namespace("team");
const cooperationModule = namespace("cooperation");

interface TextLoginFrom {
  loginId: string;
  code: string;
}

@Component({
  name: "TextLogin",
  components: {},
  filters: {
    //局部过滤器
  },
})
// 根据混入内容创建组件
export default class TextLogin extends mixins(CountDown) {
  // @UserModule.Mutation("SET_USER") setUser!: ({}) => void
  @UserModule.Action("GET_USERINFO") getUserinfo!: (type: string) => void;
  @shareModule.Mutation("SET_LOGINSHOW") setLoginShow!: (
    value: boolean
  ) => void;
  @shareModule.Mutation("SET_OFFICELOGINSHOW") setOfficeLoginShow!: (
    value: boolean
  ) => void;
  @teamModule.Mutation("SET_TEAMINVITEDLOGINSHOW") setTeamInvitedLoginShow!: (
    value: boolean
  ) => void;
  @cooperationModule.Mutation("SET_INVITEDLOGINSHOW")
  setCooperationLoginShow!: (value: boolean) => void;

  // 表单数据
  public formData: TextLoginFrom = {
    loginId: "",
    code: "",
  };
  // 验证规则
  public rules: any = {
    loginId: [
      { required: true, message: "请输入邮箱或手机号", trigger: "blur" },
      // {
      //   pattern:
      //     /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
      //   message: "请输入正确手机号",
      // },
    ],
    code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
  };

  // 获取验证码
  queryCode(): void {
    if (this.formData.loginId) {
      let params = {
        loginId: this.formData.loginId,
        modelNumber: "001",
      };
      this.isQueryCode = true;
      this.againQueryTime();
      this.$apis.getEmailCode(params).then((res: any) => {
        if (res.code === "200") {
          this.$message.success("操作成功");
        }
      });
    } else {
      this.$message.warning("请输入邮箱或手机号");
    }
  }

  // 登录
  handleLogin(): void {
    this.ruleForm.validate((valid: boolean) => {
      if (valid) {
        this.$apis
          .msgLogin({
            ...this.formData,
            ipaddr:
              (window as any).returnCitySN &&
              (window as any).returnCitySN["cip"],
            loginLocation:
              (window as any).returnCitySN &&
              (window as any).returnCitySN["cname"],
          })
          .then((res: any) => {
            if (res.code === "200") {
              // 点击保持登录存储一份token
              if (this.keep) {
                localStorage.setItem("loginId", this.formData.loginId);
              } else {
                localStorage.removeItem("loginId");
              }
              sessionStorage.setItem(
                "X-Access-Token",
                JSON.stringify(res.data)
              );
              localStorage.setItem("userId", res.data.userId);

              // this.setUser(res.data);
              this.getUserinfo("userinfo");

              // 是否登录超时返回
              if (this.$route.path.startsWith("/fileShare")) {
                // 分享页面登录
                this.setLoginShow(false);
              } else if (this.$route.path.startsWith("/officeOnline")) {
                // 文档查看页面需要重新渲染
                this.setOfficeLoginShow(false);
                window.location.reload();
              } else if (this.$route.path.startsWith("/teamInvited")) {
                // 团队邀请页面登录
                this.setTeamInvitedLoginShow(false);
              } else if (this.$route.path.startsWith("/cooperationInvited")) {
                // 协作邀请页面登录
                this.setCooperationLoginShow(false);
              } else if (this.$route.query.hasOwnProperty("path")) {
                // 重新回到之前浏览页面
                this.$router.replace(this.$route.query.path as string);
              } else {
                // 去到首页
                this.$router.push({ path: "/star" });
              }
            } else {
              this.$message.warning(res.msg);
            }
          });
      } else {
        this.$message.warning("请检查输入，不能为空！");
      }
    });
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({ required: true })
  readonly keep!: boolean;
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  @Ref("ruleForm")
  ruleForm!: any;
  //创建前钩子函数
  created(): void {
    if (localStorage.getItem("loginId")) {
      this.formData.loginId = localStorage.getItem("loginId") as string;
    }
  }
}
